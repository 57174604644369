import { HeaderContainer, HeaderLink } from "./header.styles";

function Header() {
  return (
    <div>
      <HeaderContainer>
        <HeaderLink
          onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
          to="/"
        >
          <span>HOME</span>
        </HeaderLink>
        <HeaderLink
          onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
          to="/work"
        >
          <span>WORK</span>
        </HeaderLink>
        <HeaderLink
          onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
          to="/certificates"
        >
          <span>CERTIFICATES</span>
        </HeaderLink>
        <HeaderLink
          onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
          to="/contact"
        >
          <span>CONTACT</span>
        </HeaderLink>
      </HeaderContainer>
    </div>
  );
}

export default Header;
