import { NoMatch404Container, ForImg } from "./no-match.styles";
import { ReactComponent as PageNotFound } from "../../assets/404.svg";
import CustomButton from "../../components/custom-button/custom-button.component";
import { useNavigate } from "react-router-dom";
import { mainSiteTitle } from "../../utils/main.data";
import HelmetTitleProvider from "../helmet-title-provider";

function NoMatch404() {
  const navigate = useNavigate();

  const returnToHome = () => {
    navigate("/");
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };

  return (
    <NoMatch404Container>
      <HelmetTitleProvider title={mainSiteTitle + " | Error"} />
      <ForImg>
        <PageNotFound />
      </ForImg>
      <CustomButton functions={returnToHome}>Back Home</CustomButton>
    </NoMatch404Container>
  );
}

export default NoMatch404;
