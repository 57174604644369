import React from "react";
import NoMatch404 from "../../pages/404/no-match.component";

class ErrorBoundary extends React.Component {
  constructor() {
    super();

    this.state = {
      hasErrored: false,
    };
  }

  static getDerivedStateFromError(error) {
    // process the error
    return { hasErrored: true };
  }

  componentDidCatch(error, info) {
    console.log(error);
  }

  render() {
    if (this.state.hasErrored) {
      return <NoMatch404 />;
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
