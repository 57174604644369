import styled from "styled-components";
import { mainColor, additionalColor } from "../../root/global";

export const FooterDiv = styled.div`
  padding: 50px 0 20px;
  *:focus,
  *:active {
    outline: none !important;
    -webkit-tap-highlight-color: transparent;
  }
`;

export const Wrapper = styled.ul`
  padding: 0;
  padding-bottom: 10px;
  margin: 0;
  display: flex;
  list-style: none;
  justify-content: center;
  flex-wrap: wrap;
  gap: 20px;

  @media screen and (max-width: 575px) {
    gap: 8px;
  }
`;

export const Tooltip = styled.span`
  position: absolute;
  top: 0;
  font-size: 12px;
  background: #fff;
  color: #fff;
  padding: 4px 6px;
  border-radius: 5px;
  box-shadow: 0 10px 10px rgba(0, 0, 0, 0.1);
  opacity: 0;
  pointer-events: none;
  transition: all 0.3s cubic-bezier(0.68, -0.55, 0.265, 1.55);

  &:before {
    position: absolute;
    content: "";
    height: 6px;
    width: 6px;
    background: #fff;
    bottom: -3px;
    left: 50%;
    transform: translate(-50%) rotate(45deg);
    transition: all 0.3s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  }
`;

export const Icon = styled.li`
  position: relative;
  z-index: 1;
  background: ${mainColor};
  border-radius: 50%;
  padding: 10px;
  width: 25px;
  height: 25px;
  font-size: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  box-shadow: 0 10px 10px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  transition: all 0.2s cubic-bezier(0.68, -0.55, 0.265, 1.55);

  @media screen and (max-width: 575px) {
    width: 20px;
    height: 20px;
  }

  span {
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;

    svg {
      width: 70%;

      path {
        fill: #fff;
        transition: 0.2s linear;
      }

      @media screen and (max-width: 575px) {
        width: 80%;
      }
    }
  }

  &:hover {
    background: ${additionalColor};
    color: #fff;
    span {
      text-shadow: 0px -1px 0px rgba(0, 0, 0, 0.1);
    }
  }

  &:hover ${Tooltip} {
    top: -30px;
    opacity: 1;
    visibility: visible;
    pointer-events: auto;
    text-shadow: 0px -1px 0px rgba(0, 0, 0, 0.1);

    background: ${additionalColor};
    color: #fff;
    &:before {
      background: ${additionalColor};
      color: #fff;
    }
  }
`;

export const Copyright = styled.div`
  text-align: center;
  font-size: 0.8rem;
  font-weight: 400;

  span {
    text-align: center;
    position: relative;
    z-index: 1;
  }
`;
