import { BubblyButton } from "./custom-button.styles";

function CustomButton({ functions, ...otherProps }) {
  const animateButton = (e) => {
    //reset animation
    e.target.classList.remove("animate");

    e.target.classList.add("animate");
    setTimeout(function () {
      e.target.classList.remove("animate");
    }, 700);

    // Props Functions
    if (functions) {
      functions();
    }
  };

  return (
    <BubblyButton
      onClick={animateButton}
      {...otherProps}
      disabled={otherProps.disabled}
    >
      {otherProps.children}
    </BubblyButton>
  );
}

export default CustomButton;
