import { GlobalStyle } from "./global";
import { lazy, Suspense } from "react";
import { Routes, Route } from "react-router-dom";
import Header from "../components/header/header.component";
import Footer from "../components/footer/footer.component";
import Loader from "../components/with-spinner/loader.jsx";
import ErrorBoundary from "../components/error-boundary/error-boundary.component";
import ToTopButton from "../components/to-top-button/to-top-button.component";
import Cursor from "../components/cursor/cursor.component";
import HelmetComponent from "./helmet";

const HomePage = lazy(() => import("../pages/home-page/home-page.component"));
const WorkPage = lazy(() => import("../pages/work-page/work-page.component"));
const CertificatesPage = lazy(() =>
  import("../pages/certificates-page/certificates-page.component")
);
const ContactPage = lazy(() =>
  import("../pages/contact-page/contact-page.component")
);
const NoMatch404 = lazy(() => import("../pages/404/no-match.component"));

function App() {
  return (
    <>
      <GlobalStyle />
      <HelmetComponent />
      <Header />
      <ErrorBoundary>
        <Suspense fallback={<Loader />}>
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/work" element={<WorkPage />} />
            <Route path="/certificates" element={<CertificatesPage />} />
            <Route path="/contact" element={<ContactPage />} />
            <Route path="/*" element={<NoMatch404 />} />
          </Routes>
        </Suspense>
      </ErrorBoundary>
      <Footer />
      <ToTopButton />
      <Cursor />
    </>
  );
}

export default App;
