import styled from "styled-components";

export const PrettyBg = styled.canvas`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 0;
  background-color: transparent;
  opacity: 0.1;
`;
