import { ReactComponent as GitHub } from "../assets/button-icons/github.svg";
import { ReactComponent as Codewars } from "../assets/button-icons/codewars.svg";
import { ReactComponent as Youtube } from "../assets/button-icons/youtube.svg";
import { ReactComponent as Linkedin } from "../assets/button-icons/linkedin.svg";
import { ReactComponent as Telegram } from "../assets/button-icons/telegram.svg";

const FooterLinks = [
  {
    name: "Github",
    element: <GitHub />,
    to: "https://www.github.com/topilovasilbek",
  },
  {
    name: "Codewars",
    element: <Codewars />,
    to: "https://www.codewars.com/users/asilbek-topilov",
  },
  {
    name: "Youtube",
    element: <Youtube />,
    to: "https://www.youtube.com/@Topilov-Dev",
  },
  {
    name: "Linkedin",
    element: <Linkedin />,
    to: "https://www.linkedin.com/in/asilbek-topilov",
  },
  {
    name: "Telegram",
    element: <Telegram />,
    to: "https://topilov_dev.t.me",
  },
];

export default FooterLinks;
