import styled from "styled-components";

export const NoMatch404Container = styled.div`
  min-height: 90vh;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;

  button {
    margin: 0 auto 25px;
  }
`;

export const ForImg = styled.div`
  width: 100%;

  svg {
    position: relative;
    z-index: 1;
    width: 35%;

    @media (max-width: 400px) {
      width: 100%;
    }
    @media (min-width: 401px) and (max-width: 576px) {
      width: 75%;
    }
    @media (min-width: 577px) and (max-width: 992px) {
      width: 60%;
    }
  }
`;
