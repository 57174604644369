import data from "../../utils/footer.data";
import { FooterDiv, Icon, Wrapper, Tooltip, Copyright } from "./footer.styles";

function Footer() {
  return (
    <FooterDiv>
      <Wrapper>
        {data.map(({ to, name, element }, index) => (
          <Icon onClick={() => window.open(to)} key={index}>
            <Tooltip>{name}</Tooltip>
            <span>{element}</span>
          </Icon>
        ))}
      </Wrapper>
      <Copyright>
        <span>© 2022-2024 Asilbek Topilov | All rights reserved</span>
      </Copyright>
    </FooterDiv>
  );
}

export default Footer;
