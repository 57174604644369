import styled from "styled-components";
import { globalContainer, mainColor } from "../../root/global";
import { Link } from "react-router-dom";

export const HeaderContainer = styled.div`
  ${globalContainer};
  margin: 0 auto;
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding-top: 40px;
`;

export const HeaderLink = styled(Link)`
  display: flex;
  text-decoration: none;
  color: ${mainColor};
  font-size: 20px;
  font-weight: 500;
  transition: 0.3s linear;
  position: relative;
  z-index: 1;

  @media screen and (max-width: 340px) {
    font-size: 14px;
  }

  @media screen and (min-width: 341px) and (max-width: 767px) {
    font-size: 15px;
  }

  &:hover {
    transform: translateY(-5px);
  }
`;
