import React from "react";
import { Helmet } from "react-helmet";

function HelmetComponent(props) {
  return (
    <Helmet>
      <link rel="preconnect" href="https://fonts.googleapis.com" />
      <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
      <link
        href="https://fonts.googleapis.com/css2?family=Red+Hat+Display:wght@400;500;700&display=swap"
        rel="stylesheet"
      />
    </Helmet>
  );
}

export default HelmetComponent;
