import { css, createGlobalStyle } from "styled-components";

export const mainColor = '#28344a';
export const additionalColor = '#8643dc';

export function globalContainer() {
    return css`
        @media screen and (max-width: 400px) {
            width: 95%;
        }

        @media screen and (min-width: 401px) and (max-width: 1200px) {
            width: 85%;
        }

        @media screen and (min-width: 1201px) {
            width: 1150px;
        }
    `
}

export function returnMainColor(opacityParam) {
    return css`
        color: rgba(40, 52, 74, ${opacityParam})
    `
}


export const GlobalStyle = createGlobalStyle`
    /* Style Body */
    body {
        margin: 0;
        padding: 0;
        font-family: 'Red Hat Display', sans-serif;
        background-color: #e5e5e5;
        position: relative;
        z-index: 0;
    }
    /* Style Scrollbar and Selection */
    ::-webkit-scrollbar {
        width: 8px;
        height: 5px;
        background-color: #c0c3c3;
    }
    ::-webkit-scrollbar-track {
        background-color: rgba(255, 255, 255, 0.1);
        border-radius: 8px;
    }
    ::-webkit-scrollbar-thumb {
        background-color: ${mainColor};
        border-radius: 8px;
    }
    *::selection {
        background-color: ${additionalColor};
        color: #fff;
    }
`;